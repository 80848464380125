@import './_colors.scss';
h1 {
  font-family: San-Fransisco-Pro;
  font-size: 24px;
  color: #181818;
  letter-spacing: 0;
  line-height: 29px;
}

h2 {
  font-family: San-Fransisco-Pro;
  font-size: 22px;
  color: #181818;
  letter-spacing: 0;
  line-height: 26px;
}

h3 {
  font-family: San-Fransisco-Pro;
  font-size: 20px;
  color: #181818;
  letter-spacing: 0;
  line-height: 20px;
}

h4 {
  font-family: San-Fransisco-Pro;
  font-size: 18px;
  color: #181818;
  letter-spacing: 0;
  line-height: 24px;
}

h5 {
  font-family: San-Fransisco-Pro;
  font-size: 18px;
  color: #181818;
  letter-spacing: 0;
  line-height: 20px;
}

h6,
p {
  font-family: San-Fransisco-Pro;
  font-size: 16px;
  color: #181818;
  letter-spacing: 0;
  line-height: 20px;
}

.number {
  font-family: San-Fransisco-Pro;
  font-size: 18px;
}
