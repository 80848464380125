@import './reset.scss';
@import './utilities.scss';
@import './alignment.scss';
@import './background-color.scss';
@import './background-position.scss';
@import './border.scss';
@import './border-color.scss';
@import './border-radius.scss';
@import './border-width.scss';
@import './button.scss';
@import './color.scss';
@import './cursor.scss';
@import './display.scss';
@import './flex-box.scss';
@import './float.scss';
@import './font-family.scss';
@import './font-size.scss';
@import './font-style.scss';
@import './font-weight.scss';
@import './height.scss';
@import './images.scss';
@import './inline-embeds.scss';
@import './letter-spacing.scss';
@import './line-height.scss';
@import './list.scss';
@import './opacity.scss';
@import './overflow.scss';
@import './position.scss';
@import './responsive-utilities.scss';
@import './rotate.scss';
@import './shadow.scss';
@import './spacing.scss';
@import './transition.scss';
@import './transform.scss';
@import './width.scss';
@import './z-index.scss';
@import './gradient.scss';
@import './tab.scss';
@import './heading';
@import './theme/theme.scss';
body {
  font-family: $font-family-sourceSans;
}
