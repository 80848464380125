// stylelint-disable no-eol-whitespace

/* doc
---
title: rotate
name: rotate
category: rotate
---
## rotate

`.rotate*`
e.g. `90`, `-90`, `180`, `-180`
<div class="button w80 h50 bg-white-smoke mt2 mb7 rotate90">.rotate90</div>
<div class="button w80 h50 bg-white-smoke mt2 mb3 rotate-90">.rotate-90</div>
<div class="button w80 h50 bg-white-smoke mt2 mb3 rotate180">.rotate180</div>
<div class="button w80 h50 bg-white-smoke mt2 mb3 rotate-180">.rotate-180</div>
<div class="button w80 h50 bg-white-smoke mt2 mb3 rotate270">.rotate270</div>

*/
// stylelint-enable

.rotate45 {
  transform: rotate(45deg);
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate-180 {
  transform: rotate(-180deg);
}

.rotate-90 {
  transform: rotate(-90deg);
}

.rotate270 {
  transform: rotate(270deg);
}

.rotate270-hover:hover {
  transform: rotate(270deg);
}
