
.tabbed{
    float : left;
    width : 100%;
  }
  
  .tabbed > input{
    display : none;
  }
  
  .tabbed > section > h1{
    float       : left;
    box-sizing  : border-box;
    margin      : 0;
    padding     : 0.5em 0.5em 0;
    overflow    : hidden;
    font-size   : 11px;
    font-weight : normal;
  }
  
  .tabbed > input:first-child + section > h1{
    padding-left : 1em;
  }
  
  .tabbed > section > h1 > label{
    display                 : block;
    padding                 : 0.25em 0.75em;
    background              : #fff;
    cursor                  : pointer;
       -moz-user-select     : none;
        -ms-user-select     : none;
    -webkit-user-select     : none;
  }
  
  .tabbed > section > div{
    position      : relative;
    z-index       : 1;
    float         : right;
    box-sizing    : border-box;
    width         : 100%;
    margin        : 2.5em 0 0 -100%;
    padding       : 0.5em 0.75em;
  }
  
  .tabbed > input:checked + section > h1{
    position : relative;
    z-index  : 2;
  }
  
  .tabbed > input:not(:checked) + section > div{
    display : none;
  }
  