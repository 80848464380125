/* You can add global styles to this file, and also import other style files */
@import 'primereact/resources/primereact.min.css'; //core css
@import 'primeicons/primeicons.css'; //icons
@import '../../../node_modules/primeflex/primeflex.css';
@import './styles/main.scss';

// @import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme

@font-face {
  font-family: 'San-Fransisco-Pro';
  font-style: normal;
  font-weight: 400;
  src: local('San-Fransisco-Pro'), local('San-Fransisco-Pro-Regular'), url('/assets/fonts/SF-Pro-Display-Regular.otf');
}
@font-face {
  font-family: 'San-Fransisco-Pro';
  font-style: normal;
  font-weight: 500;
  src: local('San Fransisco Pro Medium'), local('San-Fransisco-Pro-Medium'),
    url('/assets/fonts/SF-Pro-Display-Medium.otf');
}
@font-face {
  font-family: 'San-Fransisco-Pro';
  font-style: normal;
  font-weight: 700;
  src: local('San Fransisco Pro Bold'), local('San-Fransisco-Pro-Bold'),
    url('/assets/fonts/SF-Pro-Display-Semibold.otf');
}

body {
  font-family: San-Fransisco-Pro, Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande,
    sans-serif;
}

body,
#root {
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bg-PENDING,
.bg-PENDING_APPLICATION,
.bg-INVITED {
  background-color: #e7d2fe;
}

.bg-ONHOLD,
.bg-ONHOLD_APPLICATION {
  background-color: #ffeda9;
}

.bg-APPROVED,
.bg-PAID,
.bg-APPROVED_APPLICATION,
.bg-ACTIVE,
.bg-COMPLETED,
.bg-INVITE_ACCEPTED {
  background-color: $y-d-green;
}

.bg-REJECTED,
.bg-REJECTED_APPLICATION {
  background-color: $y-d-red;
}

.text-PENDING,
.text-PENDING_APPLICATION,
.text-INVITED {
  color: #591ac7;
}

.text-ONHOLD,
.text-ONHOLD_APPLICATION {
  color: #b83f07;
}

.text-APPROVED,
.text-PAID,
.text-APPROVED_APPLICATION,
.text-ACTIVE,
.text-COMPLETED,
.text-INVITE_ACCEPTED {
  color: $y-green;
}

.text-REJECTED,
.text-REJECTED_APPLICATION {
  color: $y-red;
}

.p-tabview {
  overflow-x: auto;
}

.p-tabview .p-tabview-nav li {
  width: 240px;
  text-align: center;

  @include mobile {
    width: 150px;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-left: unset;
  padding-right: unset;
  color: $y-secondary-gray;
}

.p-tabview-title {
  font-weight: 400;
}

.merchandiser-verification {
  .p-datatable-wrapper {
    height: 610px;
    overflow: scroll;

    @include mobile {
      height: unset;
      overflow-y: visible;
    }
  }
}

.outline-btn {
  min-width: 120px !important;
  height: 36px;

  @include desktop {
    min-width: 120px !important;
    height: 34px;
  }

  @include mobile {
    min-width: 100px !important;
    height: 42px;
  }
  border-radius: 4px;

  box-shadow: 2px 5px;

  .p-button-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
  }
}

.outline-btn-mob {
  @include mobile {
    min-width: 100px !important;
    height: 34px;
  }
  box-shadow: 2px 5px;
}

.p-button {
  font-size: 18px;
  @include desktop {
    font-size: 16px;
  }
}

.close {
  border-radius: 4px;
  .p-button.p-button-outlined {
    color: $y-black;
    background-color: $y-white;
    border-color: $y-black;
  }
  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: $y-black;
    background-color: $y-white;
    border-color: $y-black;
  }
}

.cancel {
  border-radius: 4px;

  .p-button.p-button-outlined {
    color: $y-blue;
  }

  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: $y-blue;
    background-color: $y-d-blue;
    border-color: $y-blue;
  }
}

.approve {
  border-radius: 4px;

  .p-button.p-button-outlined {
    color: $y-green;
  }

  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: $y-green;
    background-color: $y-d-green;
    border-color: $y-green;
  }
}

.invite {
  border-radius: 4px;

  .p-button.p-button-outlined {
    color: $y-green;
  }

  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    color: $y-green;
    background-color: $y-d-green;
    border-color: $y-green;
  }
}

.reject {
  border-radius: 4px;

  .p-button.p-button-outlined {
    color: $y-red;
  }

  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
  .p-button.p-button-outlined:enabled:active,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
  .p-button:enabled:focus,
  .p-button:not(button):not(a):not(.p-disabled):focus {
    color: $y-red;
    background-color: $y-d-red;
  }
}

.on-hold {
  border-radius: 4px;

  .p-button.p-button-outlined {
    color: $y-gold;
  }
  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
  .p-button.p-button-outlined:enabled:active,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
  .p-button:enabled:focus,
  .p-button:not(button):not(a):not(.p-disabled):focus {
    color: $y-gold;
    background-color: $y-d-gold;
  }
}

.btn-disabled {
  .p-button.p-button-outlined {
    color: $y-disabled;
  }
  &:hover {
    color: $y-disabled;
    background-color: $y-white;
  }
}

.p-dialog .p-dialog-footer button {
  margin: unset;
  width: 100%;
}

// .p-button.p-button-outlined:enabled:hover,
// .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
// .p-button.p-button-outlined:enabled:active,
// .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
// .p-button:enabled:focus,
// .p-button:not(button):not(a):not(.p-disabled):focus {
//   background-color: unset;
// }

.p-button:disabled {
  &:hover,
  .p-button.p-button-outlined:enabled:hover,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
  .p-button.p-button-outlined:enabled:active,
  .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
  .p-button:enabled:focus,
  .p-button:not(button):not(a):not(.p-disabled):focus {
    background-color: transparent !important;
  }
}

.gmnoprint {
  display: none;
}
.gm-style > div > div > a > div > img,
.gm-style-cc {
  display: none;
}
.break-all {
  word-break: break-all;
}

.fit-content {
  width: fit-content;
}

.wmin-content {
  width: min-content;
}

.wmax-content {
  width: max-content;
}
.merchandiser-name {
  margin-right: unset !important;
}

.common-dialog {
  width: 600px !important;
  height: auto;

  @include desktop {
    width: 60vw !important;
    height: auto;
  }

  @include mobile {
    width: 90vw !important;
    height: auto;
  }

  input {
    color: $y-secondary-gray !important;
  }
}

@media print {
  .header,
  .sidebar-nav,
  .paged-btn-col {
    display: none !important;
  }
}
