// stylelint-disable no-eol-whitespace

/* doc
---
title: height
name: height
category: height
---
## height

Height class: `.h\*`, e.g. `h50`
Max and min heights: `.maxh*` and `.minh*`
Values are referenced by pixel value but converted to rem in the css.
See the code for available sizes.
Max and min classes are not responsive.

Example:
<div class="w160 h40 h80-tb h160-dk bg-gainsboro mb3">h40 h80-tb h160-dk</div>

*/
// stylelint-enable

@import './_breakpoints.scss';

.min-height-viewport {
  min-height: 100vh;
}

/* Heights */

$heights: (
  0: 0,
  4: 0.25rem,
  6: 0.375rem,
  7: 0.438rem,
  10: 0.625rem,
  12: 0.75rem,
  13: 0.813rem,
  14: 0.875rem,
  15: 0.938rem,
  16: 1rem,
  18: 1.125rem,
  20: 1.25rem,
  22: 1.375rem,
  24: 1.5rem,
  25: 1.5625rem,
  26: 1.625rem,
  28: 1.75rem,
  30: 1.875rem,
  32: 2rem,
  34: 2.125rem,
  36: 2.25rem,
  38: 2.375rem,
  40: 2.5rem,
  42: 2.625rem,
  43: 2.688rem,
  46: 2.875rem,
  48: 3rem,
  50: 3.125rem,
  55: 3.43rem,
  58: 3.625rem,
  60: 3.75rem,
  62: 3.875rem,
  64: 4rem,
  65: 4.063rem,
  70: 4.37rem,
  72: 4.5rem,
  80: 5rem,
  86: 5.375rem,
  90: 5.625rem,
  92: 5.8rem,
  95: 5.938rem,
  96: 6rem,
  100: 6.25rem,
  104: 6.45rem,
  120: 7.5rem,
  125: 7.81rem,
  130: 8.125rem,
  134: 8.375rem,
  140: 8.75rem,
  155: 9.688rem,
  158: 9.875rem,
  160: 10rem,
  166: 10.375rem,
  180: 11.25rem,
  175: 10.938rem,
  190: 11.875rem,
  197: 12.313rem,
  200: 12.5rem,
  220: 13.75rem,
  225: 14.063rem,
  235: 14.68rem,
  240: 15rem,
  247: 15.438rem,
  250: 15.63rem,
  258: 16.125rem,
  265: 16.56rem,
  273: 16.88rem,
  280: 17.5rem,
  285: 17.8125rem,
  295: 18.4375rem,
  300: 18.75rem,
  310: 19.375rem,
  340: 21.25rem,
  342: 21.375rem,
  343: 21.438rem,
  344: 21.5rem,
  348: 21.75rem,
  350: 21.875rem,
  370: 23.125rem,
  395: 24.688rem,
  388: 24.25rem,
  400: 25rem,
  418: 26.125rem,
  427: 26.687rem,
  430: 26.87rem,
  450: 28.125rem,
  460: 28.75rem,
  470: 29.375rem,
  500: 31.25rem,
  515: 32.1875rem,
  530: 33.125rem,
  570: 35.62rem,
  588: 36.75rem,
  600: 37.5rem,
  627: 39.1875rem,
  632: 39.5rem,
  695: 43.438rem,
  700: 43.75rem,
  728: 45.5rem,
  750: 46.875rem,
  800: 50rem,
  860: 53.75rem,
  1055: 66rem,
  1078: 67.375rem,
  auto: auto,
  inherit: inherit,
  '8p': 8%,
  '10p': 10%,
  '20p': 20%,
  '23p': 23%,
  '24p': 24%,
  '25p': 25%,
  '29p': 29%,
  '30p': 30%,
  '32p': 32%,
  '33p': 33%,
  '37p': 37%,
  '38p': 38%,
  '40p': 40%,
  '44p': 44%,
  '48p': 48%,
  '49p': 49%,
  '50p': 50%,
  '54p': 54%,
  '58p': 58%,
  '59p': 59%,
  '60p': 60%,
  '66p': 66%,
  '68p': 68%,
  '69p': 69%,
  '70p': 70%,
  '74p': 74%,
  '75p': 75%,
  '55p': 55%,
  '80p': 80%,
  '82p': 82%,
  '84p': 84%,
  '90p': 90%,
  '100p': 100%
);

@each $name, $val in $heights {
  .h#{$name} {
    height: #{$val};
  }

  .minh#{$name} {
    min-height: #{$val};
  }

  .maxh#{$name} {
    max-height: #{$val};
  }
}

@each $name, $val in $heights {
  @include nm(h#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include dk(minh#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include nm(minh#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include nm(maxh#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include mob(maxh#{$name}) {
    height: #{$val};
  }
}
@each $name, $val in $heights {
  @include less-device(maxh#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include bt-mob-dk(maxh#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include bt-lap-4k(maxh#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include tb(h#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include dk(h#{$name}) {
    height: #{$val};
  }
}
@each $name, $val in $heights {
  @include mob(h#{$name}) {
    height: #{$val};
  }
}

@each $name, $val in $heights {
  @include less-device(h#{$name}) {
    height: #{$val};
  }
}

.maxh60 {
  max-height: 3.75rem;
}

.maxh0 {
  max-height: 0;
}

.maxh390 {
  max-height: 24.375rem;
}

.maxh2500 {
  max-height: 93.75rem;
}

.maxh570 {
  max-height: 35.62rem;
}

.h100p-mb {
  @include mobile {
    height: 100%;
  }
}

// .h100p-less-device {
//   @include less-device {
//     height: 100%;
//   }
// }
