// stylelint-disable no-eol-whitespace

/* doc
---
title: font size
name: font size
category: font size
---
## font size
<p class="f11">.f11</p>
<p class="f12">.f12</p>
<p class="f14">.f14</p>
<p class="f15">.f15</p>
<p class="f16">.f16</p>
<p class="f17">.f17</p>
<p class="f18">.f18</p>
<p class="f20">.f20</p>
<p class="f22">.f22</p>
<p class="f24">.f24</p>
<p class="f26">.f26</p>
<p class="f28">.f28</p>
<p class="f30">.f30</p>
<p class="f32">.f32</p>
<p class="f34">.f34</p>
<p class="f36">.f36</p>
<p class="f38">.f38</p>
<p class="f42">.f42</p>
<p class="f44">.f44</p>
<p class="f48">.f48</p>
*/
// stylelint-enable

@import './_breakpoints.scss';

$sizes: (
  8: 0.5rem,
  9: 0.5625rem,
  10: 0.625rem,
  11: 0.688rem,
  12: 0.75rem,
  13: 0.813rem,
  14: 0.875rem,
  15: 0.9375rem,
  16: 1rem,
  17: 1.063rem,
  18: 1.125rem,
  20: 1.25rem,
  21: 1.313rem,
  22: 1.375rem,
  24: 1.5rem,
  26: 1.625rem,
  28: 1.75rem,
  30: 1.875rem,
  32: 2rem,
  34: 2.125rem,
  36: 2.25rem,
  38: 2.375rem,
  40: 2.5rem,
  42: 2.625rem,
  44: 2.75rem,
  48: 3rem,
  60: 3.75rem
);

@each $name, $val in $sizes {
  .f#{$name} {
    font-size: #{$val};
  }
}

@each $name, $val in $sizes {
  @include tb(f#{$name}) {
    font-size: #{$val};
  }

  @include nm(f#{$name}) {
    font-size: #{$val};
  }

  @include mob(f#{$name}) {
    font-size: #{$val};
  }

  @include less-device(f#{$name}) {
    font-size: #{$val};
  }
}

@each $name, $val in $sizes {
  @include dk(f#{$name}) {
    font-size: #{$val};
  }

  @each $name, $val in $sizes {
    @include mob(f#{$name}) {
      font-size: #{$val};
    }
  }

  @each $name, $val in $sizes {
    @include less-device(f#{$name}) {
      font-size: #{$val};
    }
  }
}
